import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import {usePagination} from "../../common/hooks/usePagination";
import {useSort} from "../../common/hooks/useSort";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {useParams} from "react-router-dom";
import {useFilter} from "../../common/hooks/useFilter";
import {useCsvExport} from "../../common/hooks/useCsvExport";
import {Log} from "../../common/log";
import {ParsedDataDetailComponent} from "../../domain/traits/traits";
import {useAuthContext} from "../../common/context/authContext";
import {useNotificationContext} from "../../notifications/notificationContext";
import {MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE} from "../../deviceTypes/queries";
import {DEFAULT_DATA_TABLE_CONFIG} from "../../deviceTypes/deviceTypesDetailPage";
import {useT} from "../../common/i18n";
import { thirtyDays } from "../../common/magicNumbers";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";
import "moment/locale/de";
import "moment/locale/cs";
import moment from "moment";
import i18next from "i18next";

const QUERY_PARSED_DATA = gql`
    query ($page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType, $devTypeId: ID!, $orgId: ID) {
        parsedData(page: $page, filter: $filter, sort: $sort, devTypeId: $devTypeId, orgId: $orgId) {
            id
            time
            receivedAt
            createdAt
            deviceId
            dataRaw
            type
            sensorDataId
            device {
                id
                name
                addr
                description
                propertiesRaw
            }
        }
    }
`;

const maxExportableItems = 100 * 1000;

const DeviceTypeParsedData = () => {
    const license = useContext(FeatureContext)
    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <FeatureNotEnabled />
    }
    const t = useT();
    moment.locale(i18next.language);
    Log.Debug("DeviceTypeParsedData.init: moment locale updated", "currentLocale", i18next.language, "moment.locale", moment.locale());

    // update moment locale when language changes
    useEffect(() => {
        // Assuming `t` provides the current locale
        moment.locale(i18next.language);
        Log.Debug("DeviceTypeParsedData.useEffect: moment locale updated", "currentLocale", i18next.language, "moment.locale", moment.locale());
    }, [t]);

    const params = useParams();
    const deviceTypeId = params.deviceTypeId;
    const auth = useAuthContext();

    const notify = useNotificationContext();

    let initialFilter = [{"field": 'receivedAt', "op": "lte", "value": new Date(Date.now())},
        {"field": 'receivedAt', "op": "gte", "value": new Date(Date.now()-thirtyDays)}]
    const filters = useFilter(true, initialFilter);
    const sort = useSort(null);
    //const sort = useSort({property: "receivedAt", direction: "desc"});
    const page = usePagination(100);

    const deviceTypeResult = useQuery(QUERY_DEVICE_TYPE, {
        variables: {
            id: deviceTypeId,
        }
    });

    const parsedDataResult = useQuery(QUERY_PARSED_DATA, {
        variables: {
        devTypeId: deviceTypeId,
          orgId: auth.organisationId(),
          sort: sort.getGraphqlSortInput(),
          filter: filters.getGraphqlFilterInput(),
          page: page.getGraphqlPageInput(),
    }})

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceTypeId},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceTypeId,
            }
        }]
    });

    useEffect(() => {
        Log.Debug("DeviceTypeParsedData.useEffect: reset parsedData params deviceTypeId", deviceTypeId)
        sort.reset()
        filters.reset()
        page.reset()
    }, [deviceTypeId]);


    // Takes parsed data and unmarshal json fields
    const parsedDataMapper = (d) => {
        return {
            ...d,
            data: JSON.parse(d.dataRaw),
            //deviceProperties: JSON.parse(d.device.propertiesRaw || "{}") || {},
            dataRaw: undefined, // Hide dataRaw
        };
    };

    let exportedCount = 0;
    let exportedFrom = ""
    let exportedTo = ""

    const csvExport = useCsvExport(QUERY_PARSED_DATA, {
        stickyNotification: true,
        maxItems: maxExportableItems,
        customSuccessMessage: () => (
            <div>
                {t("device-type.data-table.export.successful", "Export was successful")} <br />
                {exportedCount >= maxExportableItems ? (
                    <>
                        {t("device-type.data-table.export.max-items", "Maximum number of entries reached")}
                        <br />
                    </>
                ) : null}
                ---
                <br />
                {t("device-type.data-table.export.entries", "Entries")}: {exportedCount} <br />
                {t("filter-panel.from", "From")}: {moment(exportedFrom).format("DD.MM.YYYY HH:mm:ss")} <br />
                {t("filter-panel.until", "Until")}: {moment(exportedTo).format("DD.MM.YYYY HH:mm:ss")} <br />
                {exportedCount > 1 &&
                    t("device-type.data-table.export.duration", "Duration") +
                        ": " +
                        moment.duration(moment(exportedFrom).diff(moment(exportedTo))).humanize()}{" "}
                {exportedCount > 1 && <br />}
            </div>
        ),
        variables: {
            devTypeId: deviceTypeId,
            orgId: auth.organisationId(),
            filter: filters.getGraphqlFilterInput(),
            sort: sort.getGraphqlSortInput(),
        },
        dataExtractor: (d) => {
            let result = d.data.parsedData.map(parsedDataMapper)
            // the data extractor might see more items than setup in maxExportableItems
            // because it is called before the actual export to csv
            let lastItemIndex = result.length - 1

            exportedCount = exportedCount + result?.length
            // the csv export honours the max exportable items, so we can not get more than maxExportableItems
            if (exportedCount >= maxExportableItems) {
                lastItemIndex = exportedCount - (exportedCount - maxExportableItems) - 1
                exportedCount = maxExportableItems
            }
            if (exportedTo === "" && result[0]?.receivedAt !== undefined) {
                // write only the first element
                exportedTo = result[0]?.receivedAt
            }
            if (result[lastItemIndex]?.receivedAt !== undefined) {
                // overwrite until the last element
                exportedFrom = result[lastItemIndex]?.receivedAt
            }

            return result
        },
    })

    // reset export stats when done / not processing
    useEffect(() => {
        if (!csvExport.isProcessing) {
            exportedCount = 0;
            exportedFrom = ""
            exportedTo = ""
        }

    }, [csvExport.isProcessing]);

    const mainSpinner = useGraphqlLoadingComponent(deviceTypeResult);
    if(mainSpinner) {
        return mainSpinner
    }

    let deviceType = deviceTypeResult?.data?.deviceType;
    let tableConfig = deviceType?.dataTableConfigRaw && JSON.parse(deviceTypeResult.data.deviceType.dataTableConfigRaw);

    if (!tableConfig) {
        tableConfig = DEFAULT_DATA_TABLE_CONFIG;
    }

    const prefixCols = [{
        heading: t("device-type.data-table-config.heading.received", "Received"),
        csvFormat: "{{date receivedAt 'DD.MM.YYYY HH:mm:ss'}}",
        cell: {
            format: "{{date receivedAt 'DD.MM.YYYY HH:mm:ss'}}",
        }
    },
        {
            heading: t("device-type.data-table-config.heading.device", "Device"),
            csvFormat: "{{#if device.name}}{{device.name}}{{else}}- no name -{{/if}}",
            cell: {
                format: "{{#if device.name}}{{device.name}}{{else}}- no name -{{/if}}",
                href: "#/organisation/devices/{{device.id}}/device-data",
            }
        },
        {
            heading: t("device-type.data-table-config.heading.address", "Address"),
            csvFormat: "{{device.addr}}",
            cell: {
                format: "{{device.addr}}",
                href: "#/organisation/devices/{{device.id}}/device-data",
            }
        }];

    const data = parsedDataResult.data;

    return <GenericDataTable
        id={"parsed-data-table"}
        fixedLayout={false}
        tableConfigDefault={tableConfig}
        handleTableConfigSave={(values) => {
            return updateDeviceType({
                variables: {
                    input: {
                        dataTableConfig: JSON.stringify(values),
                    }
                }
            }).then(() => {
                notify.info("Data Table Config for Device Type updated.")
            }).catch((err) => {
                notify.error(t("device-type.table-config.update-failed", "Failed to save Table Config"), err);
            });
        }}
        items={data?.parsedData.map(({dataRaw, ...d}) => {
            return {
                ...d,
                data: JSON.parse(dataRaw)
            };
        })}
        // TODO: render trait based details: renderDetails={(item) => <WmbusDetailComponent item={item}/>}
        prefixCols={prefixCols}
        renderDetails={(item) => <ParsedDataDetailComponent traits={deviceType.deviceTraits} data={item}/>}
        useGqlLoadingSpinner={false}
        gqlResult={parsedDataResult}
        sort={sort}
        page={page}
        filters={filters}
        csvExport={csvExport}
    />;
};

export default DeviceTypeParsedData;

DeviceTypeParsedData.propTypes = {
    "deviceTypeId": PropTypes.number,
    "tableConfig": PropTypes.object,
};
